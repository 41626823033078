<de-header [hamburgerMenuAlways]="currentUrl !== '/login' && currentUrl !== '/login#faq'" [isExternal]="welcomeLogin"
    siteTitle="RestoreOps{{showEnvironment ? ' ('+environment+')' : ''}}" [headerLinks]="headerLinks" (navigation)="navigated($event)"
    (hamburgerMenuClick)="headerDrawerIsOpen = true" (dukeLogoClick)="goToHome()">
</de-header>
<de-drawer [(drawerIsOpen)]="headerDrawerIsOpen" [drawerGreeting]="drawerGreeting" [headerLinks]="sidebarLinks"
    (navigation)="navigated($event)">
</de-drawer>

<div class="main">
    <router-outlet></router-outlet>
</div>

<!-- Contractor Terms modal -->
<de-modal role="dialog" tabindex="-1" [open]="termsOpen" [showMask]="true" [showHeader]="false" [showClose]="false">
    <div tabindex="0" position="centered" class="[ de-modal-body ] view-tc">
        <p class="modal-header">Terms and Conditions</p>
        <fieldset class="de-form__group" (click)="boxIsChecked()" *ngIf="termsRead">
            <legend class="de-form__legend"></legend>
            <de-checkbox [id]="'checkbox2'" [(value)]="boxChecked" [label]="'I have read and reviewed Terms and Conditions'" ></de-checkbox>
            <!-- If you click Disagree, you will be logged out of RestoreOps -->
        </fieldset>
        <p class="modal-body" *ngIf="!termsRead">Please read and review the Terms and Conditions</p>
        <p class="modal-body-check" *ngIf="termsRead">*If you select Disagree, you will be logged out of RestoreOps.</p>
        <div class="view-tc-group">
            <a href="https://www.duke-energy.com/Legal/Terms-of-Use" target="_blank">
                <de-button type="text" size="medium" (click)="viewingTerms()" *ngIf="!termsRead">Terms and Conditions</de-button>
            </a>
        </div>
        <div class="view-tc-group" *ngIf="termsRead">
            <button class="ghost-button de" (click)="closeTermsModal()" [disabled]="!boxChecked">Disagree</button>
            <button class="filled-button de" (click)="acceptTerms()" [disabled]="!boxChecked"
                [class.loading]="isAccepting">Agree</button>
        </div>
    </div>
</de-modal>

<de-footer [showSlogan]="false" *ngIf="welcomeLogin"></de-footer>