export const environment = {
  "B2CClientId": "c955d194-0321-4d62-a46e-0bed2a7da5ee",
  "awsBaseUrl": "https://nbn62pr9vd.execute-api.us-east-1.amazonaws.com/prod/restoreops",
  "azure_client_id": "c955d194-0321-4d62-a46e-0bed2a7da5ee",
  "azure_redirect_uri": "https://restoreops.duke-energy.app/welcome",
  "azure_tenant": "1de0a68b-bc03-4462-a1da-6a3868bece0d",
  "envName": "prod",
  "msalConfig": {
    "scopes": [
      "https://DukeEnergyB2C.onmicrosoft.com/dab2capi/user_impersonation"
    ]
  },
  "production": "true",
  "tenantPolicyConfig": {
    "B2CEndPoint": "https://dukeenergyb2c.b2clogin.com/dukeenergyb2c.onmicrosoft.com/",
    "employeeSignInPolicy": "B2C_1_DA_Employee_Sign_Up_Sign_In",
    "forgotPasswordPolicy": "B2C_1_Password_Reset_DA",
    "logOutRedirectUri": "https://restoreops.duke-energy.app/welcome",
    "redirectUri": "https://restoreops.duke-energy.app/welcome",
    "signInPolicy": "B2C_1_SignIn_DA",
    "signUpPolicy": "B2C_1_SignUp_DA"
  },
  "vsac_url": "https://outage-maps-internal-web.appc-dlbcorp-ee1dhpb.duke-energy.com"
}
