import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from './faq/faq.component';
import { AccordionComponent } from './accordion/accordion.component';
import { FileDropComponent } from './file-drop/file-drop.component';
import { DeCardsComponent } from './de-cards/de-cards.component';
import { SortChipsComponent } from './sort-chips/sort-chips.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SearchInputComponent } from './search-input/search-input.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FilterCardComponent } from './filter-card/filter-card.component';
import { MatOptionModule } from '@angular/material/core';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DeButtonModule, DeModalModule } from '@de-electron/electron-angular-components';



@NgModule({
  declarations: [
    FaqComponent,
    AccordionComponent,
    FileDropComponent,
    DeCardsComponent,
    SortChipsComponent,
    PaginatorComponent,
    SearchInputComponent,
    FilterCardComponent,
    ContactUsComponent
  ],
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatOptionModule,
    DeModalModule,
    DeButtonModule
  ],
  exports: [
    FaqComponent,
    AccordionComponent,
    FileDropComponent,
    DeCardsComponent,
    SortChipsComponent,
    PaginatorComponent,
    SearchInputComponent,
    FilterCardComponent,
    ContactUsComponent
  ]
})
export class SharedComponentsModule { }
