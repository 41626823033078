<div class="de-accordion">
    <ul class="de-accordion__items">
      <li class="de-accordion__item">
        <button type="button" (click)="toggleExpansion()" [attr.aria-expanded]="expanded"
          class="de-accordion__title">
          <span>{{ title }}</span>
        </button>
        <div [attr.aria-hidden]="!expanded" class="de-accordion__content">
          <ng-content></ng-content>
        </div>
      </li>
    </ul>
</div>
