import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { TermsGuard } from './terms.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'welcome'
    // redirectTo: 'login'
  },
  {
    path: 'welcome',
    loadChildren: () => import('./modules/welcome/welcome.module').then(m => m.WelcomeModule),
    canDeactivate: [TermsGuard]
    // canDeactivate can not leave page
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'uploadroster',
    loadChildren: () => import('./modules/upload-roster/upload-roster.module').then(m => m.UploadRosterModule),
    canActivate: [AuthGuard]
    // canActivate can enter page
  },
  {
    path: 'training',
    loadChildren: () => import('./modules/training/training.module').then(m => m.TrainingModule),
  },
  {
    path: 'workers',
    loadChildren: () => import('./modules/worker/worker.module').then(m => m.WorkerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contactus',
    loadChildren: () => import('./modules/contactus-page/contactus-page.module').then(m => m.ContactusPageModule)
  }
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})


export class AppRoutingModule { }
