import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication/authentication.service';
import { AppConfig } from '../config/app.config';
import { DeSnackbarService } from '@de-electron/electron-angular-components';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { DeSnackbarType } from '@de-electron/electron-angular-components/lib/de-snackbar/de-snackbar-config';

@Injectable({
  providedIn: 'root'
})
export class ContactusService {

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private config: AppConfig,
    private snackbar: DeSnackbarService
  ) { }

  // Contact Us post call, submit form to back-end endpoint
  postContactUs(message: string, email: string, phone: string, version: string) {

    const contactUsRequest = {
      // message: message,
      // email: email,
      // phone: phone,
      // version: navigator.userAgent
      // Short hand version of what is above.
      message, email, phone, version: navigator.userAgent
    };

    return this.http.post<any>(`${this.config.getConfig("awsBaseUrl")}/email`, contactUsRequest).pipe(
      map(
        response => {
          console.log(response)
          this.showSnackbar("Contact Us form sent", "success")
          return response
        },
        error => {
          this.showSnackbar("Error, Issue sending contact us form.", "warning")
          return error
        }
      ),
      catchError(
        err => {
          this.showSnackbar("Error, Issue sending contact us form.", "warning")
          return throwError(err)
        }
      )
    )
  }

  showSnackbar(text: string, type: DeSnackbarType) {
    this.snackbar.show({
      type: type,
      text: text,
      clickEventContent: "text",
      duration: 4000,
      desktopPosition: 'bottom'
    });
  }

}