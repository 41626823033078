<!-- <p>filter-card works!</p> -->
<!-- 3 parts Header,Body, 2 dropdowns, Active/Inactive dropdown, Company Dropdown -->
<div class="filter-card-container">
  <div class="filter-card-container-header">
    <p class="filter-card-container-title">
      Filter
    </p>
      <button class="filterXButton" (click)="closeFilter.emit()"><img src="assets/icons/close-white-xsmall.svg" alt="close-img"></button>
  </div>
  <div class="filter-card-container-body">
    <div class="filter-card-container-filters">
      <!-- Dropdown Here -->
      <mat-form-field appearance="fill">
        <mat-label>Status</mat-label>
        <mat-select [ngModel]="selectedStatus"(ngModelChange)="statusUpdated($event)">
          <mat-option value="All">All</mat-option>
          <mat-option value="Active">Active</mat-option>
          <mat-option value="Inactive">Inactive</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Company</mat-label>
        <!-- This is shorthand -->
        <!-- <mat-select [(ngModel)]="selectedCompany"> -->
        <mat-select [(ngModel)]="selectedCompany" (ngModelChange)="companyUpdated($event)">
          <mat-option *ngFor="let company of companies" [value]="company">
            {{company}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="filter-card-container-buttons">
    <button class="[ ghost-button ]" (click)="clearFilters()">CLEAR</button>
    <button class="[ filled-button ]" (click)="updateFilters()">APPLY</button>
  </div>
</div>