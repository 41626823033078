import { EventEmitter, Input } from '@angular/core';
import { Component, HostListener, OnInit, Output } from '@angular/core';
import { FileType } from 'src/app/models/roster/roster';

@Component({
  selector: 'app-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent implements OnInit {

  @Input() fileTypes: FileType[];
  @Output() fileSelect: EventEmitter<File[]> = new EventEmitter();
  fileOver = false;

  constructor() {}

  ngOnInit(): void {
  }

  fileBrowseHandler(event: FileList) {
    const files = event;
    this.fileSelect.emit(this.fileList(files));
  }

  fileList(list: FileList): File[] {
    const result = [];
    for (let i = 0; i < list.length; i++) {
      result.push(list.item(i));
    }
    return result;
  }

  fileTypesString() {
    return this.fileTypes.join(', ');
  }

  fileTypesDisplay() {
    const capTypes = this.fileTypes.map(t => t.toUpperCase());
    return `${capTypes.slice(0, capTypes.length - 1).join(', ')} and ${capTypes[capTypes.length - 1]}`;
  }

  @HostListener('dragover', ['$event']) onDragOver(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    const files = event.dataTransfer.files;
    this.fileSelect.emit(this.fileList(files));
  }
}