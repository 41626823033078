<!-- <de-modal role="dialog" tabindex="-1" [open]="contactUsOpen" [showMask]="true" [showHeader]="false" [showClose]="true"> -->
    <de-modal role="dialog" tabindex="-1" [open]="contactUsOpen" [showMask]="true" [showHeader]="false" [showClose]="false"
    [width]="'350px'">
    <div tabindex="0" position="centered" class="[ de-modal-body ] view-contactus">
        <p class="modal-header">
            Tell us what happened
        </p>
        <p class="modal-body">
            Please provide us with a description of your issue, an email address, and the best phone
            number where we can get in contact with you.
        </p>
        <form [formGroup]="formGroup">
            <!-- DESCRIPTION FIELD -->
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput placeholder="Description of Issue..." formControlName="description"
                    maxlength="500"></textarea>
                <mat-error *ngIf="formGroup.get('description').hasError('required')">
                    Description is <strong>required</strong>
                </mat-error>
                <mat-hint align="end">{{formGroup.value.description?.length || 0}} / 500</mat-hint>
            </mat-form-field>
            <!-- EMAIL ADDRESS FIELD -->
            <mat-form-field appearance="outline">
                <mat-label>Email Address</mat-label>
                <input type="text" matInput placeholder="Email Address" formControlName="email_address" maxlength="50">
                <mat-error *ngIf="formGroup.get('email_address').hasError('required')">
                    Email Address is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="!formGroup.get('email_address').hasError('required') && formGroup.get('email_address').invalid">
                    Email Address is <strong>invalid</strong>
                </mat-error>
                <!-- ASK LINDASY about character limit on email? -->
                <mat-hint align="end">{{formGroup.value.email_address?.length || 0}} / 50</mat-hint>
            </mat-form-field>
            <!-- PHONENUMBER FIELD -->
            <mat-form-field appearance="outline">
                <mat-label>Phone Number</mat-label>
                <input type="tel" matInput placeholder="Phone Number" formControlName="phone_number" maxlength="14">
                <mat-error *ngIf="formGroup.get('phone_number').hasError('required')">
                    Phone Number is <strong>required</strong>
                </mat-error>
                <mat-error
                    *ngIf="!formGroup.get('phone_number').hasError('required') && formGroup.get('phone_number').invalid">
                    Phone Number is <strong>invalid</strong>
                </mat-error>
            </mat-form-field>
        </form>
        <button class="filled-button de" (click)="submitContactUsForm()" [disabled]="!formGroup.valid">
            <!-- Submit's to service now -->
            Submit
        </button>
        <button class="ghost-button de" (click)="closeContactUs()">
            Cancel
        </button>
        <!-- </div> -->
    </div>
</de-modal>