<div class="search-container{{showBorder ? ' show-border' : ''}}" (click)="focusInput()">
  <img src="assets/icons/search-01.svg" alt="search-img">
  <!-- <mat-icon>search</mat-icon> -->
  <!-- <ng-container *ngIf="autocomplete">
    <input #input [placeholder]="placeholder" type="search" [formControl]="valueCtrl" (input)="updateSearchValue()"
      (focus)="searchFocus.emit()" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option role="option" *ngFor="let val of filteredValues | async" [value]="val">
         {{val.first_name}} {{val.last_name}}
      </mat-option>
    </mat-autocomplete>
  </ng-container> -->
  <ng-container>
    <input #input [placeholder]="placeholder" type="search" [formControl]="valueCtrl" (input)="updateSearchValue()"
      (focus)="searchFocus.emit()">
  </ng-container>
</div>
