import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContactusService } from 'src/app/services/contactus.service';
import { formatPhoneNumber } from 'src/app/utility';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy {
  // Tells if the modal is not open.
  @Input() contactUsOpen = false;
  // Tell if the contact us modal has be closed
  @Output() closed = new EventEmitter();

  formGroup = new FormGroup({
    description: new FormControl("", Validators.required),
    email_address: new FormControl("", [Validators.required, Validators.pattern(/^\w+([+\.'-]\w+)*@\w+(-|\.|\w+)*\.(com|net|org)$/i)]),
    phone_number: new FormControl("", [Validators.required, Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/)])
  });

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private contactservice: ContactusService,
  ) { }

  ngOnInit(): void {
    // Phone validation
    this.formGroup.get('phone_number').valueChanges
      .pipe(takeUntil(this._destroying$))
      .subscribe(value => {
        this.formGroup.get('phone_number').setValue(formatPhoneNumber(value, this.formGroup.value.phone_number), { emitEvent: false });
        this.formGroup.get('phone_number').updateValueAndValidity({ emitEvent: false });
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  submitContactUsForm() {
    this.contactservice.postContactUs(this.formGroup.value.description, this.formGroup.value.email_address, this.formGroup.value.phone_number, this.formGroup.value.version).subscribe(
      response => {
        console.log(response)
       this.closeContactUs()
      },
      error => {
        
      }
    )
  }

  closeContactUs() {
    this.closed.emit();
    this.formGroup.reset()
  }

}