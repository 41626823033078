<div class="chips">
  <h6 class="chips-title">Sort By</h6>
  <div class="chips-list">
    <button *ngFor="let chip of sortChips" class="chips-list-chip" [class.active]="chip.direction !== 0"
      (click)="toggleChip(chip)">
      <h6>{{chip.name}}</h6>
      <img [class.asc]="chip.direction === 1" [class.desc]="chip.direction === -1"
      src="../../../../assets/icons/arrow-up.svg">
    </button>
  </div>
</div>