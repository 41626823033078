import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  page: PageEvent = {
    pageIndex: undefined,
    length: undefined,
    previousPageIndex: undefined,
    pageSize: undefined
  };
  pageSizeOptions = [25, 50, 75, 100];

  /**
   * Length of list being paged
   */
  @Input() set length(length: number) {
    this.page.length = length;
  }

  /**
   * Current page offset (e.g. offset = 1 with limit = 5 indicates we are viewing items 5-9)
   */
  @Input() set offset(offset: number) {
    this.page.pageIndex = offset;
  }
  @Output() offsetChange = new EventEmitter<number>();

  /**
   * Number of items per page
   */
  @Input() set limit(limit: number) {
    this.page.pageSize = limit;
  }
  @Output() limitChange = new EventEmitter<number>();

  @Output() updated = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Updates the selected page options
   *
   * @param pageEvent (Optional) Material paginator page event
   */
  pageChange(pageEvent?: Partial<PageEvent>) {
    this.page = {
      pageIndex: pageEvent?.pageIndex ?? 0,
      pageSize: pageEvent?.pageSize ?? 10,
      previousPageIndex: pageEvent?.previousPageIndex ?? undefined,
      length: pageEvent?.length ?? 0
    };

    this.offsetChange.emit(this.page.pageIndex);
    this.limitChange.emit(this.page.pageSize);
    this.updated.emit();
  }

  /**
   * Whether or not a given index is within the currently selected page
   *
   * @param i Index
   */
  //  indexIsOnPage(i: number) {
  //   const minIndex = this.page?.pageIndex * this.page?.pageSize;
  //   const maxIndex = this.page?.pageIndex * this.page?.pageSize + this.page?.pageSize - 1;
  //   return minIndex <= i && i <= maxIndex;
  // }
}