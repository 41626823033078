import { Component, OnInit } from '@angular/core';
// import { DeAccordionItem } from '@de-electron/electron-angular-components';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  contactUsOpen = false;

  constructor() { }

  ngOnInit(): void {
  }

}
