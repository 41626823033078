import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppConfig } from './config/app.config';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DeFooterModule, DeHeaderModule, DeAccordionModule, DeDrawerModule, DeSnackbarModule, DeModalModule, DeButtonModule, DeCheckboxModule } from '@de-electron/electron-angular-components';
import { AppRoutingModule } from './app-routing.module';
import { SharedComponentsModule } from './modules/shared-components/shared-components.module';
import { TermsGuard } from './terms.guard';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './config/msal.config';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    DeFooterModule,
    DeHeaderModule,
    DeAccordionModule,
    DeDrawerModule,
    DeModalModule,
    DeButtonModule,
    DeCheckboxModule,
    AppRoutingModule,
    SharedComponentsModule,
    DeSnackbarModule,
    MsalModule,
  ],
  providers: [AppConfig,
    TermsGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: getUseFactory,
      deps: [AppConfig],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
export function getUseFactory(config: AppConfig) {
  return () => config.load();
}
