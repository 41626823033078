import { Component, OnInit, Input, ChangeDetectorRef, OnChanges } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {

  @Input() title: string;

  expanded = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleExpansion() {
    this.expanded = !this.expanded;
  }

}
