<div class="upload-dropzone" [ngClass]="{ 'upload-over': fileOver }">
  <ng-content></ng-content>
  <button class="[ filled-button de ]" (click)="fileRef.click()">SELECT FILES</button>
  <!-- <p>File types accepted {{ fileTypesDisplay() }}</p> -->
  <p>File type accepted .CSV</p>
  <p class="arcos-text">Upload your ARCOS roster template. If using a ROD roster template, please email that roster to
    <a href="mailto:RestoreOps@duke-energy.com" id="roEmail">RestoreOps@duke-energy.com</a> instead of uploading it.
  </p>
  <p class="arcos-text">Example ARCOS Roster template can be found here: <a href="../../../../assets/template/ARCOS-TEMPLATE.csv" id="roEmail">ARCOS Template</a></p>

  <input #fileRef type="file" id="fileInput" accept="{{ fileTypesString() }}"
    (change)="fileBrowseHandler($event.target.files)">
</div>