import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RosterService } from './services/roster.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
  ) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.msalBroadcastService.inProgress$
      .pipe(
        tap(status => console.log(status)),
        filter((status: InteractionStatus) => status !== InteractionStatus.HandleRedirect),
        map(user => {
          return !!this.auth.currentUser()
        })
      );
  }

}
