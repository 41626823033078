import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface AutocompleteObject {
  first_name?: string;
  last_name?: string;
  id?: string;
  email?: string;
  user_cell_phone?: string;
}

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {

  valueCtrl = new FormControl();

   /**
   * Whether or not to show the rounded border around the field
   */
    @Input() showBorder = true;
  
    @Input() placeholder = 'Search';
  
    /**
     * Value of search field
     */
    @Input()
    set searchValue(val: string) {
      this.value = val;
    }
    @Output() searchValueChange = new EventEmitter<string>();
  
    /**
     * Change event
     */
    @Output() changed = new EventEmitter<void>();
  
    @Output() searchFocus = new EventEmitter();
  
    @ViewChild('input') input: ElementRef<HTMLInputElement>;

  constructor() {
   }

  ngOnInit(): void {
  }

  get value() {
    return this.valueCtrl.value;
  }

  set value(val: string) {
    this.valueCtrl.setValue(val);
  }

  updateSearchValue() {
    this.searchValueChange.emit(this.value);
    this.changed.emit();
  }

  focusInput() {
    this.input.nativeElement.focus();
  }

}
