<div class="bottom-container">
    <h1 id="faq"> Frequently Asked Questions </h1>
    <p id="faq1"> If you have a question that isn’t answered below, please <button id="faq2"
            (click)="contactUsOpen=true">Contact Us</button>
    </p>

    <div class="bottom-container-details">

        <app-accordion title="How do I download the RestoreOps application?">
            <ul id="list-items">
                <li>Navigate to the Google Play Store or Apple App Store by clicking on the icons above.</li>
                <li> When you reach the application store click download and the RestoreOps application will begin
                    downloading.</li>
                <li>This needs to be done on a mobile device.You can also use the following direct links:</li>
                <li> Google Play (Android): <a
                        href="https://play.google.com/store/apps/details?id=com.dukeenergy.da.prod" target="_blank"
                        id="faq2">
                        https://play.google.com/store/apps/details?id=com.dukeenergy.da.prod</a></li>
                <li> Apple App Store (iOS): <a href="https://apps.apple.com/us/app/restoreops/id1527474657"
                        target="_blank" id="faq2">https://apps.apple.com/us/app/restoreops/id1527474657</a></li>
            </ul>
        </app-accordion>
        <app-accordion title="Why won't the Apple app store let me download the app?">
            <ul id="list-items">
                <li>You may not be able to download from the Apple App Store due to not having a credit card associated
                    with the account (new Duke Employee on a Duke device)</li>
                <li>You can either use a Duke CC or your own, personal CC as the RestoreOps app is free
                    to download once a card is associated with their Apple account</li>
                <li>You will NOT be charged, as the app is free</li>
                <li>This is an APPLE requirement and is not specific to the RestoreOps app</li>
            </ul>
        </app-accordion>
        <app-accordion title="What email address am I supposed to use to log into the app?">
            <ul id="list-items">
                <li>If you have a Duke email address, please use that email address, even if you are a contractor.</li>
                <li>If you are a contractor without a Duke email address, use the email address submitted with your
                    roster (typically your standard work email)</li>
            </ul>
        </app-accordion>
        <app-accordion title="I'm a Duke employee, how do I sign in?">
            <ul id="list-items">
                <li>Open the app to login and choose "Duke Energy Email"</li>
                <li>You will be prompted to use the "b2clogin.com" to Sign In</li>
                <li>Hit Continue and sign into the b2c login with you Duke credentials</li>
                <li>The Microsoft Authenticator will ask if you want to approve the login</li>
                <li>Select Yes and Unlock the Authenticator</li>
                <li>Select to navigate BACK to RestoreOps it the top left of the screen</li>
                <li>You will now be signed into the app</li>
                <li>You must now accept the terms and conditions before the app allows you progress forward</li>
            </ul>
        </app-accordion>
        <app-accordion title="I'm a Duke employee and I can't log in. What do I do?">
            <ul id="list-items">
                <li>You may be using the wrong credentials. Check to ensure your email address and password are entered
                    in correctly. Check if there are extra spaces in either field.</li>
                <li>You may have forgotten their password. If so, run through the password reset steps here: <a
                        href="https://passwordreset.microsoftonline.com/" target="_blank">Password Reset</a>
                </li>
                <li>For unresolved issues, contact the <a
                        href="https://dukeenergy.service-now.com/de" target="_blank">Enterprise Help
                        Desk (EHD)</a></li>
            </ul>
        </app-accordion>
        <app-accordion title="How do I login with a non-Duke email?">
            <ul id="list-items">
                <li>Check the email that you provided for the Damage Assessment roster.</li>
                <li>You should have received an email with a temporary password and instructions on how to login with
                    that password.</li>
                <li>If you did not receive an email,check with your DA lead to make sure that your roster has been
                    uploaded to our database.</li>
                <li>If you are having trouble registering please <button id="faq2" (click)="contactUsOpen=true">Contact
                        Us.</button></li>
            </ul>
        </app-accordion>
        <app-accordion title="I'm a contractor and I can't log in. What do I do?">
            <ul id="list-items">
                <li>Check to see if you received an email notification. If not, it likely means that your roster has not
                    been uploaded.</li>
                <li>Please contact your DA Coordinator or DA Lead and make sure they’ve uploaded a roster with this
                    email. If they have any questions or need help, have them <button id="faq2"
                        (click)="contactUsOpen=true">
                        Contact Us.</button></li>
                <li>Try to use the RestoreOps <a
                        href="../../../../assets/template/RestoreOps Forgot Password Instructions.docx" id="faq2">Forgot
                        Password Instructions.docx</a></li>
                <li>If neither of these work,<button id="faq2" (click)="contactUsOpen=true">Contact Us
                    </button> again.</li>
            </ul>
        </app-accordion>
        <app-accordion title=" I'm a contractor and didn't receive/can't find my email notification. What do I do?">
            <ul id="list-items">
                <li>Check your spam or junk mail. If you're unable to find it, use the <a
                        href="../../../../assets/template/RestoreOps Forgot Password Instructions.docx"
                        id="faq2">"Forgot Password" process.</a></li>
                <li>Please contact your DA Coordinator or DA Lead and make sure they’ve uploaded a roster with this
                    email. If they have any questions or need help, have them <button id="faq2"
                        (click)="contactUsOpen=true">Contact Us.</button>
                </li>
            </ul>
        </app-accordion>
        <app-accordion title="I'm a contractor and can't find the temporary password email. What do I do?">
            <ul id="list-items">
                <li>Check your spam or junk mail.</li>
                <li>If you're unable to find it, use the <a
                        href="../../../../assets/template/RestoreOps Forgot Password Instructions.docx"
                        id="faq2">"Forgot Password" process.</a></li>
            </ul>
        </app-accordion>
        <app-accordion title="I'm logged in, but can't see my Ops Center or Evaluator (DA) Team in RestoreOps?">
            <ul id="list-items">
                <li>Users will now ONLY see the ops centers that have events/tickets assigned to crews</li>
                <li>Once an event/ticket is assigned to a crew, your ops center and will become visible</li>
                <li>Once you know there is an event/ticket assigned to your crew, log in and your ops center and
                    Evaluator (DA) Team should be available for selection</li>
            </ul>
        </app-accordion>
        <app-accordion
            title="After authenticating with Azure B2C, I go to log into RestoreOps and the app provides a message that says I have wrong username or password. What do I do?">
            <ul id="list-items">
                <li>You are using an old version of the app and will need to update</li>
                <li>Please visit the appropriate app store (Apple or Google Play listed above) and download the
                    latest version of the app</li>
                <li>If you have previously downloaded the RestoreOps app and are running <span class="bold">v1.14 or
                        below</span>, AND are
                    receiving an error message of “username/password is incorrect” you MUST <span class="bold"> delete
                        the current app
                        and re-download the app from the above links.</span></li>
            </ul>
        </app-accordion>
        <app-accordion title="What happens if I am having intermittent connectivity issues?">
            <ul id="list-items">
                <li>It is recommended to use RestoreOps with a STABLE internet connection</li>
                <li>Make sure device meets the use criteria: Apple's iOS version 14.8 and above, and Android's
                    operating system version 7.0 and above</li>
                <li>We currently do not natively support iPads/tablets, so if using RestoreOps with a tablet device,
                    note that you may run into some issues </li>
                <li>Make sure you have cellular data turned ON for areas with spotty WiFi</li>
                <li>Turn WiFi OFF if relying on cellular data (phones could be trying to connect to WiFi that
                    doesn’t exist)</li>
                <li>If still encountering issues, close the app and reopen it</li>
                <li>If still encountering issues, delete and reinstall the app</li>
                <li>If still encountering issues, get OFF the Duke VPN</li>
                <li>At any point,<span class="bold">DO NOT LOG OUT</span> or information will be lost</li>
                <li>Once you have signal, the app will try and upload your assessments</li>
            </ul>
        </app-accordion>
        <app-accordion title=" How do I approve the terms and conditions?">
            <ul id="list-items">
                <li>All Duke Energy employees and contractors must review and accept the Terms and Conditions in
                    order to move forward</li>
                <li>Press "Terms and Conditions" button and another tab is opened in their browser: <a
                        href="https://www.duke-energy.com/legal/terms-of-use ">
                        https://www.duke-energy.com/legal/terms-of-use
                    </a></li>
                <li>Once the T&C tab is opened, <span class="bold">go back to the RestoreOps app</span> and the original
                    login page will
                    display a pop-up with a checkbox to the left of "I have read and reviewed Terms and Conditions"
                    and Disagree and Agree in INACTIVE state</li>
                <li>You MUST check the checkbox of "I have read and reviewed Terms and Conditions" is checked,
                    Disagree and Agree buttons update to ACTIVE state </li>
                <li>If you select Agree button, pop-up will close and page will return to original homepage</li>
                <li>If you select Disagree button, then prompt changes to "Terms and Conditions" prompt once again
                    and will not allow you to move forward</li>
            </ul>
        </app-accordion>
        <app-accordion title="How do I get back to the application after accepting terms and conditions?">
            <ul id="list-items">
                <li>Go back to the app, swipe up from the bottom and go back to the RO app</li>
                <li>Touch at the bottom of the screen, press/hold and swipe up to the middle of the screen, should
                    see the other apps you have used (history), tap on RO and go back to the app</li>
            </ul>
        </app-accordion>
        <app-accordion title="What if I don't know my jurisdiction, ops center and truck/crew?">
            <ul id="list-items">
                <li>If a contractor, call the Damage Assessment Coordinator or Contractor Lead</li>
                <li>If a Duke employee, call the Damage Assessment Coordinator</li>
            </ul>
        </app-accordion>
        <app-accordion title="What if I can't see my crew name?">
            <ul id="list-items">
                <li>Make sure an event is assigned. You WON'T see a crew name until an event is assigned</li>
                <li>Check to see you have selected the correct jurisdiction and ops center on the Account tab</li>
                <li>Contact your Damage Assessment Coordinator (DAC) to see if they’ve created your crew in OMS</li>
                <li>If using SCORP, make sure the DAC has put the crew name in the field coordinator field</li>
                <li>You may need to refresh by going back to the account screen and selecting the ops center again.
                    Refresh may take up to 10 minutes, OR press Refresh button in the top right for immediate
                    results.</li>
            </ul>
        </app-accordion>
        <app-accordion title="What mobile operating systems are compatible with the RestoreOps application?">
            The RestoreOps application is compatible with Apple's iOS version 14.8 and above, and Android's
            operating system version 7.0 and above. We currently do not support iPads/tablets.
        </app-accordion>
        <app-accordion title="How do I submit assessments?">
            <ul id="list-items">
                <li>Select the event from the event list to see details</li>
                <li>Click into the event and use the tabs at the top to either see list view (default) or map view
                </li>
                <li>At the bottom of the event details screen, you will see the option to "Add Assessment"</li>
                <li>First, set the damage location by pressing and holding anywhere on the map</li>
                <li>You can move the dropped pin to be more precise by zooming in and pressing and holding wherever
                    you want to drop it.</li>
                <li>If any part of the damage is NOT accessible, toggle "Truck Accessible" to "No," otherwise, leave
                    it as "Yes" and tab "Confirm Damage Location" to move on to the rest of the assessment options
                </li>
                <li class="indent">Be sure to take a picture of the inaccessible damage if you select that option</li>
                <li>Work through the assessment details until you see the option to submit</li>
            </ul>
        </app-accordion>
    </div>

</div>

<app-contact-us [contactUsOpen]="contactUsOpen" (closed)="contactUsOpen=false"></app-contact-us>