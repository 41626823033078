<div class="de-cards-rectangular" *ngIf="type === 'rectangular'">

    <ul class="de-cards-rectangular__group" *ngIf="!ordered">

        <li *ngFor="let card of cards" class="de-cards-rectangular__item">
            <div class="de-cards-rectangular__card">
                <picture class="de-cards-rectangular__media">
                    <img class="de-cards-rectangular__image" [src]="card.imagePath" alt="">
                </picture>
                <div class="de-cards-rectangular__body">
                    <h3 class="de-cards-rectangular__title">
                        <!-- <a *ngIf="card.link"><span>{{ card.title }}</span></a> -->

                        <!-- <span *ngIf="!card.link">{{ card.title }}</span> -->
                        {{ card.title }}
                    </h3>

                    <div class="de-cards-rectangular__available" *ngIf="showAvailableText === true">
                        {{ card.available }}
                    </div>
                    
                    <div class="de-cards-rectangular__description">
                        {{ card.description }}
                    </div>
                    <button class="de-cards-rectangular__button" (click)="clickEvent(card.link)" [disabled]="!card.enable">
                        <a *ngIf="card.link" ><span>{{ card.button }}</span></a>

                        <span *ngIf="!card.link">{{ card.button }}</span>
                    </button>
                </div>
            </div>
        </li>
</ul>

  <ol class="de-cards-rectangular__group" *ngIf="ordered">

          <li *ngFor="let card of cards" class="de-cards-rectangular__item">
              <div class="de-cards-rectangular__card">
                  <picture class="de-cards-rectangular__media">
                      <img class="de-cards-rectangular__image" [src]="card.imagePath" alt="">
                  </picture>
                  <div class="de-cards-rectangular__body">
                      <h3 class="de-cards-rectangular__title">
                          <a *ngIf="card.link" (click)="clickEvent(card.link)"><span>{{ card.title }}</span></a>

                          <span *ngIf="!card.link">{{ card.title }}</span>
                      </h3>
                      <div class="de-cards-rectangular__description">
                          {{ card.description }}
                      </div>
                    </div>
              </div>
          </li>
  </ol>
</div>

