import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { WelcomeComponent } from './modules/welcome/welcome/welcome.component';
import { AuthenticationService } from './services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class TermsGuard implements CanDeactivate<WelcomeComponent> {
  constructor (
    private auth: AuthenticationService
  ) {}
  async canDeactivate(
    component: WelcomeComponent, 
    currentRoute: ActivatedRouteSnapshot, 
    currentState: RouterStateSnapshot, 
    nextState?: RouterStateSnapshot
    ) {
    return !this.auth.currentUser() || this.auth.termsChecked || !(await this.auth.contractorHasExpired());
  }
  
  
}
