import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication/authentication.service';

const whitelisted = [
  /https:\/\/duke-energy-restoreops-roster-(sbx|dev|tst|qa|prod)\.s3\.amazonaws\.com/,
  /https:\/\/duke-energy-restoreops-training-document-(sbx|dev|tst|qa|prod)\.s3\.amazonaws\.com/,
]

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthenticationService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return from(this.auth.bearerToken().catch(e => "")).pipe(
      mergeMap(
        token => whitelisted.some(url => url.test(request.url))
        ? next.handle(request)
        : next.handle(request.clone({
          headers: request.headers.set('Authorization', token)
        })).pipe(
          catchError(e => {
            if (e && e instanceof HttpErrorResponse && e.status === 401) {
              console.log('logging out')
              this.auth.logout();
            }
            return throwError(e);
          })
        )
      )


    );
  }
}
