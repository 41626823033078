import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkerFilter } from '../../worker/worker/worker.component';

@Component({
  selector: 'app-filter-card',
  templateUrl: './filter-card.component.html',
  styleUrls: ['./filter-card.component.scss']
})
export class FilterCardComponent implements OnInit {

  selectedStatus: string ;
  selectedCompany: string;

  @Input() companies = [];

  // used to close the filter when X is clicked
  @Output() closeFilter = new EventEmitter();

  @Output() applyFilter = new EventEmitter<WorkerFilter>();


  constructor() { }

  ngOnInit(): void {
  }

  statusUpdated(status: string) {
    this.selectedStatus = status;
    // console.log(status)
  }

  companyUpdated(company: string) {
    this.selectedCompany = company;
    // console.log(company)
  }

  updateFilters() {
    this.applyFilter.emit({
      status:this.selectedStatus,
      company:this.selectedCompany
    })
  }

  clearFilters() {
    this.selectedStatus = "All";
    this.selectedCompany = undefined;
    this.updateFilters()
  }

}
