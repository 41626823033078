import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

export enum SortChipDirection {
  ASC = 1,
  DESC = -1,
  NONE = 0
}

export interface SortChip<T> {
  name: string;
  direction: SortChipDirection;
  propertyNames?: string[][];
  comparator: (a: T, b: T) => number;
}

@Component({
  selector: 'app-sort-chips',
  templateUrl: './sort-chips.component.html',
  styleUrls: ['./sort-chips.component.scss']
})
export class SortChipsComponent<T> implements OnInit, OnChanges {

  /**
   * List of items to be sorted
   */
  @Input() items: T[];

  /**
   * Objects representing properties to be sorted, and how to order each property
   */
  @Input() sortChips: SortChip<T>[];

  /**
   * Sort chip emitted when a change is made
   */
  @Output() chipUpdated = new EventEmitter<SortChip<T>>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.sort();
  }

  /**
   * Toggles the direction of a sort chip and re-sorts the list provided
   *
   * Goes from NONE -> ASC -> DESC -> NONE -> ...
   *
   * @param chip Sort chip
   */
  toggleChip(chip: SortChip<T>) {
    switch (chip.direction) {
      case SortChipDirection.NONE:
        chip.direction = SortChipDirection.ASC;
        break;
      case SortChipDirection.ASC:
        chip.direction = SortChipDirection.DESC;
        break;
      default:
        chip.direction = SortChipDirection.NONE;
    }
    this.sort();
    this.chipUpdated.emit(chip);
  }

  getDeepestProperty(obj: T, propertyNames: string[][]) {
    const firstValidField = (value: any) => (prevField, currField) => value[prevField] ? prevField : currField;
    return propertyNames?.reduce(
      (value, propertyNameList) => value ? value[propertyNameList.reduce(firstValidField(value), undefined)] : undefined, obj
    ) ?? undefined;
  }

  /**
   * Sorts the items provided using the first non-zero
   * comparator for each item in the list
   */
  sort() {
    this.items.sort(
      this.sortChips.reduce(
        (comparator, chip) =>
          (a: T, b: T) =>
            comparator(a, b) === 0
              ? (chip.propertyNames?.length > 0 && this.getDeepestProperty(a, chip.propertyNames) === undefined)
                ? (chip.propertyNames?.length > 0 && this.getDeepestProperty(b, chip.propertyNames) === undefined)
                  ? 0
                  : 1
                : (chip.propertyNames?.length > 0 && this.getDeepestProperty(b, chip.propertyNames) === undefined)
                  ? -1
                  : chip.direction * chip.comparator(a, b)
              : comparator(a, b),
        (_, __) => 0
      )
    );
  }
}