import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DeCard } from './de-cards.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

export type CardType = 'rectangular';

@Component({
  selector: 'de-cards',
  templateUrl: './de-cards.component.html',
  styleUrls: ['./de-cards.component.scss']
})
export class DeCardsComponent implements OnInit {

  showAvailableText = false;

  @Output() linkClick: EventEmitter<String> = new EventEmitter<String>();
  @Input() cards: DeCard[];
  @Input() ordered = false;
  @Input() type: CardType = 'rectangular';


  constructor(
    private auth: AuthenticationService,
  ) { }
  

  ngOnInit() {
    if (!this.auth.currentUser()) {
      this.showAvailableText = true
    } else {
      this.showAvailableText = false
    }
  }

  clickEvent(link: string) {
    this.linkClick.emit(link);
    console.log(link);
  }
}

