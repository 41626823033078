import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfig } from 'src/app/config/app.config';
import { Router, UrlSegment } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PhoneResponse, WorkerResponse } from 'src/app/models/roster/worker';
import { DeSnackbarService } from '@de-electron/electron-angular-components';
import { DeSnackbarType } from '@de-electron/electron-angular-components/lib/de-snackbar/de-snackbar-config';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, InteractionRequiredAuthError } from '@azure/msal-browser';

const EMAIL = 'email';
const PHONE = 'phone';
const FIRST_NAME = 'fname';
const LAST_NAME = 'lname';

export interface ApigeeOAuthToken {
  refresh_token_expires_in: string;
  user_email: string;
  api_product_list_json: string[];
  app_access_list: string;
  token_type: string;
  issued_at: string;
  client_id: string;
  access_token: string;
  refresh_token: string;
  user_id: string;
  scope: string;
  refresh_token_issued_at: string;
  expires_in: string;
  refresh_count: string;
}

export interface ContractorConfigData {
  "data": {
    "omsAutoSyncInterval": string,
    "assessmentAutoSyncInterval": string,
    "opCenterRefresh": boolean,
    "expired": boolean,
    "userId": string,
    "acceptedTerms": boolean
  },
  "errorMessages": [],
  "hasError": boolean
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  TokensSet$ = new BehaviorSubject<boolean>(null)
  termsChecked = false;



  constructor(
    private config: AppConfig,
    private router: Router,
    private http: HttpClient,
    private snackbar: DeSnackbarService,
    private msalService: MsalService
  ) { }

  showSnackbar(text: string, type: DeSnackbarType) {
    this.snackbar.show({
      type: type,
      text: text,
      clickEventContent: "text",
      duration: 15000,
      desktopPosition: 'top'
    });
  }

  public externalLogin(redirect: string, userInfo?: PhoneResponse): void {
    this.msalService.loginRedirect({
      prompt: "login",
      authority: `${this.config.getConfig("tenantPolicyConfig").B2CEndPoint}${this.config.getConfig("tenantPolicyConfig").signInPolicy}`,
      scopes: this.msalScopes.scopes
    })
  }

  public employeeLogin(redirect: string): void {
    if (!this.currentUser()) {
      this.msalService.loginRedirect({
        authority: `${this.config.getConfig("tenantPolicyConfig").B2CEndPoint}${this.config.getConfig("tenantPolicyConfig").employeeSignInPolicy}`,
        scopes: this.msalScopes.scopes
      })
    }
  }

  public logout() {
    // Forces account selection on next login
    // sessionStorage.removeItem('auth_token');
    // sessionStorage.removeItem('cdda_access_token');
    // sessionStorage.removeItem('cdda_access_expire_time');
    // sessionStorage.removeItem('cdda_refresh_token');
    // sessionStorage.removeItem('cdda_refresh_expire_time');
    localStorage.removeItem("checkingTerms")
    localStorage.clear();
    this.msalService.logout()
  }
  public currentUser(): AccountInfo {
    return this.msalService.instance.getAllAccounts()?.[0] ?? undefined;
  }

  public signUp(phone: string, firstName: string, lastName: string, redirect: string, userInfo: PhoneResponse): void {
    if (!this.currentUser()) {
      this.msalService.loginRedirect({
        authority: `${this.config.getConfig("tenantPolicyConfig").B2CEndPoint}${this.config.getConfig("tenantPolicyConfig").signUpPolicy}`,
        scopes: ['https://DukeEnergyTestB2C.onmicrosoft.com/dab2capi/user_impersonation']
      })
    }
  }
  public forgotPassword(): void {
    this.msalService.loginRedirect({
      authority: `${this.config.getConfig("tenantPolicyConfig").B2CEndPoint}${this.config.getConfig("tenantPolicyConfig").forgotPasswordPolicy}`,
      scopes: ['https://DukeEnergyTestB2C.onmicrosoft.com/dab2capi/user_impersonation']
    })
  }

  get currentUserEmail() {
    return this.currentUser()?.username ?? '';
  }

  get isEmployee() {
    return (this.currentUser()?.idTokenClaims as any)?.tfp?.toLowerCase()?.includes("employee")
  }

  get msalToken() {
    return localStorage.getItem("msal.idtoken")
  }

  async verifyUser(userInfo: PhoneResponse) {
    const options = this.getRequestOptions()
    const user = {
      ...userInfo.data,
      "email": this.currentUserEmail,
      "verified": true
    }
    return this.http.post(`${this.config.getConfig("awsBaseUrl")}/rc/register`, user, await options).toPromise()
    // return this.http.post(`${this.config.getConfig("awsBaseUrl")}/rc/register`, user, await options).toPromise()
  }

  async isContractorExpired() {
    return this.http.get<ContractorConfigData>(
      `${this.config.getConfig("awsBaseUrl")}/config/user`,
    ).pipe(
      map(
        response => response?.data?.expired ?? true
      )
    ).toPromise();
  }

  // GET /restoreops/config/user : Checking terms
  async hasAcceptedTerms() {
    return this.http.get<ContractorConfigData>(
      `${this.config.getConfig("awsBaseUrl")}/config/user`,
    ).pipe(
      map(
        response => {
          console.log(response)
          return response?.data?.acceptedTerms ?? false
        }
      )
    ).toPromise();
  }

  // POST /restoreops/terms : accepting terms
  async acceptTerms() {
    // return this.http.post<boolean>(
    return this.http.post<ContractorConfigData>(
      `${this.config.getConfig("awsBaseUrl")}/terms`,
      true
    ).toPromise();
  }

  private registerUser(phone: string, firstName: string, lastName: string, redirect: string, userInfo: PhoneResponse): void {
    this.msalScopes.extraQueryParameters = [];
    this.msalScopes.extraQueryParameters[PHONE] = phone;
    if (firstName) {
      this.msalScopes.extraQueryParameters[FIRST_NAME] = firstName;
    }
    if (lastName) {
      this.msalScopes.extraQueryParameters[LAST_NAME] = lastName;
    }
    sessionStorage.setItem("RO_registering", "True")
    if (redirect) {
      sessionStorage.setItem("RO_redirect", redirect)
    }
    if (userInfo) {
      sessionStorage.setItem("RO_phoneResponse", JSON.stringify(userInfo))
    }
    this.msalService.loginRedirect()
  }

  private authenticateUser(redirect: string, userInfo?: PhoneResponse): void {
    this.msalScopes.extraQueryParameters = [];
    sessionStorage.setItem("RO_authenticating", "True")
    if (redirect) {
      sessionStorage.setItem("RO_redirect", redirect)
    }
    if (userInfo) {
      sessionStorage.setItem("RO_phoneResponse", JSON.stringify(userInfo))
    }
    this.msalService.loginRedirect()
  }

  async contractorHasExpired() {
    if (!this.isEmployee && await this.isContractorExpired().catch(e => true)) {
      this.showSnackbar("This account has expired. If you are actively supporting a storm and need to access RestoreOps, please contact your supervisor.", "warning");
      setTimeout(() => {
        // If expired log them out and return them to login page. this.router.navigate(["/login"]) 
        this.logout();
        this.router.navigate(["/login"]);
      }, 15000);
      return true;
    }
    return false;
  }


  get msalTenantConfig() {
    return this.config.getConfig('tenantPolicyConfig');
  }
  get msalScopes() {
    return this.config.getConfig('msalConfig');
  }
  get msalConfig() {
    const clientId = this.config.getConfig('azure_client_id');
    const tenantConfig = this.msalTenantConfig;
    const ua = window.navigator.userAgent;
    const isIEEdge = /MSIE|Trident|Edge\//.test(ua);
    const cacheLocation = isIEEdge === true ? 'sessionStorage' : 'localStorage';
    return {
      auth: {
        clientId,
        authority: tenantConfig.B2CEndPoint + tenantConfig.signInPolicy,
        validateAuthority: false,
        navigateToLoginRequestUrl: false,
        redirectUri: tenantConfig.redirectUri
      },
      cache: {
        cacheLocation,
        storeAuthStateInCookie: isIEEdge,
      },
      FrameworkOptions: {
        isAngular: true
      }
    };
  }

  async getRequestOptions() {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return { headers: headers };
  }

  /**
   * Gets access token from session storage or retrieves a new token
   */
  bearerToken(): Promise<string> {
    console.log(this.currentUser())
    return new Promise((resolve, reject) => {
      // if (!this.currentUser()) {
      //   reject("user not signed in")
      // }
      const accessTokenRequest = {
        scopes: this.msalScopes.scopes,
        authority: `${this.config.getConfig("tenantPolicyConfig").B2CEndPoint}${this.currentUser().idTokenClaims["tfp"]}`,
        account: this.currentUser(),
      };
      this.msalService.instance
        .acquireTokenSilent(accessTokenRequest)
        .then(accessTokenResponse => {
          resolve(`Bearer ${accessTokenResponse.accessToken}`);
        })
        .catch(error => {
          //Acquire token silent failure, and send an interactive request
          if (error instanceof InteractionRequiredAuthError) {
            this.msalService.instance
              .acquireTokenRedirect(accessTokenRequest)
          } else {
            console.error(error);
            reject(error);
          }
        });
    });
  }

}

