import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DrawerGreeting, HeaderLink } from '@de-electron/electron-angular-components';
import { AppConfig } from './config/app.config';
import { AuthenticationService } from './services/authentication/authentication.service';
import { Worker } from '../../src/app/models/roster/worker';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/config/env.config';


export type SidebarLink = HeaderLink;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnDestroy {

  termsOpen = false;
  termsRead = false;

  boxChecked = false;
  beingChecked = false;

  isAccepting = false;
  // hasAccepted = false;

  private readonly _destroying$ = new Subject<void>();


  Worker: Worker;

  //Links used in the navbar
  headerLinks: HeaderLink[] = [];

  //Links for the sidebar
  sidebarLinks: SidebarLink[] = [];

  //Header path shown

  drawerGreeting: DrawerGreeting = {
    viewValue: "RestoreOps"
  }

  headerDrawerIsOpen = false;

  currentUrl = "";

  environment: string;
  showEnvironment: boolean;


  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private config: AppConfig,
    private http: HttpClient
  ) {
    const environment = this.config.getConfig('envName');
    this.showEnvironment = environment.toLowerCase() !== 'prod'
      && environment.toLowerCase() !== 'production';
    this.environment = `${environment?.charAt(0)?.toUpperCase()}${environment?.substr(1, environment?.length)}`;
    this.environment = this.environment === 'Tst'
      ? 'Test'
      : this.environment === 'Qa'
        ? 'QA'
        : this.environment;
    this.router.events.pipe(
      filter(e=> e instanceof NavigationEnd),
      tap((e: NavigationEnd) => {
          this.currentUrl = e.url;
          this.updateHeaderLinks();
      }),
      filter(_ => !!this.auth.currentUser()),
      take(1)
    )
      .subscribe(
        e => {
          this.checkTerms
        }
      )
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


  get welcomeLogin() {
    return this.currentUrl === '/welcome' || this.currentUrl === '/login' || this.currentUrl === '/contactus' || this.currentUrl === '/'
  }

  goToHome() {
    return this.router.navigate(['/welcome'])
  }


  updateHeaderLinks() {

    const isEmployee = this.auth.isEmployee


    if (this.currentUrl.includes("/login")) {
      this.headerLinks = [
        {
          viewValue: 'Frequently Asked Questions',
          value: '/login#faq',
          showUserIcon: false
        }
      ];
    } else {
      if (isEmployee) {
        this.sidebarLinks = [
          {
            viewValue: 'Training Material',
            value: this.auth.currentUser() ? '/training' : "/login?redirect=training",
            showUserIcon: false,
            icon: this.auth.currentUser() ? undefined : "de-lock"
          },
          {
            viewValue: 'Upload Roster',
            value: this.auth.currentUser() ? '/uploadroster' : "/login?redirect=upload-roster",
            showUserIcon: false,
            icon: this.auth.currentUser() ? undefined : "de-lock"
          },
          {
            viewValue: 'View Roster',
            value: this.auth.currentUser() ? '/workers' : "/login?redirect=workers",
            showUserIcon: false,
            icon: this.auth.currentUser() ? undefined : "de-lock"
          },
          {
            viewValue: 'VSAC link',
            value: this.auth.currentUser() ? 'vsaclink' : "/login?redirect=vsaclink",
            showUserIcon: false,
            icon: this.auth.currentUser() ? undefined : "de-lock"
          },
          {
            viewValue: 'Power BI Metrics',
            value: this.auth.currentUser() ? 'powerbimetrics' : "/login?redirect=powerbimetrics",
            showUserIcon: false,
            icon: this.auth.currentUser() ? undefined : "de-lock"
          }
        ];
      } else {
        this.sidebarLinks = [
          {
            viewValue: 'Training Material',
            value: '/training',
            showUserIcon: false
          }
          // what the contractor sees
          // {
          //   viewValue: 'Upload Roster',
          //   value: this.auth.currentUser() ? '/uploadroster' : "/login?redirect=upload-roster",
          //   showUserIcon: false,
          //   icon: this.auth.currentUser() ? undefined : "de-lock"
          // },
          // {
          //   viewValue: 'View Roster',
          //   value: this.auth.currentUser() ? '/workers' : "/login?redirect=workers",
          //   showUserIcon: false,
          //   icon: this.auth.currentUser() ? undefined : "de-lock"
          // }
        ];
      }
      if (this.auth.currentUser()) {
        console.log(this.auth.currentUser())
        this.headerLinks = [
          {
            // Write a (or) to pull in worker name... Shows name on Login button
            // viewValue: this.auth.currentUser().name,
            viewValue: this.auth.currentUser().name || `${(this.auth.currentUser().idTokenClaims as any).given_name} ${(this.auth.currentUser().idTokenClaims as any).family_name}`,
            value: 'user',
            showUserIcon: true,
            subLinks: [
              {
                viewValue: 'Logout',
                value: 'logout'
              },
            ]
          }
        ]
      } else {
        this.headerLinks = [
          {
            viewValue: 'Login',
            value: '/login',
            showUserIcon: true
          },
        ];
      }
    }
  }
  async navigated(link: HeaderLink) {
    if (link.value === "/login#faq") {
      document.getElementById("faq").scrollIntoView({ behavior: "smooth" })
      return
    } else if (link.value === "vsaclink") {
      window.open(environment.vsac_url, '_blank');
    } else if (link.value === "powerbimetrics") {
      window.open('https://app.powerbi.com/groups/me/apps/8909e49c-024b-41f7-aa78-cfac4857703b/reports/5bcdb695-5e81-4964-9ba6-84d1354be5b8/ReportSection554fd544ffea6234d1a1?ctid=2ede383a-7e1f-4357-a846-85886b2c0c4d', '_blank');
    } else if (link.value === "logout") {
      this.auth.logout()
      this.updateHeaderLinks()
    } else if (link.value !== 'user') {
      this.router.navigateByUrl(link.value)
    }
  }

  checkTerms() {
    if (!this.auth.isEmployee) {
      this.auth.hasAcceptedTerms().then(
        response => {
          if (!response) {
            this.openTermsModal()
          } else {
            this.auth.termsChecked = true;
          }
        }
      ).catch(
        err => {
          this.openTermsModal()
        }
      )
    } else {
      console.log('CheckingTerms')
      this.auth.termsChecked = true;
    }
  }

  acceptTerms() {
    this.isAccepting = true;
    this.auth.acceptTerms().then(
      response => {
        this.termsOpen = false;
        this.auth.termsChecked = true;
      }
    ).catch(
      err => { console.log(err) }
    ).finally(
      () => {
        this.isAccepting = false;
      }
    )
  }

  async openTermsModal() {
    if (await this.auth.isContractorExpired().catch(e => true)) {
      this.termsOpen = false;
    } else {
      this.termsOpen = true;
    }
  }

  closeTermsModal() {
    this.auth.logout();
  }

  viewingTerms() {
    this.termsRead = true;
  }

  termsChecked() {
    this.boxChecked = true;
  }

  boxIsChecked() {
    if (this.beingChecked = true) {
      this.boxChecked = true
    } else {
      this.boxChecked = false
    }
  }



}