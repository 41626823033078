import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { ILoggerCallback } from '@azure/msal-common';
import { env } from 'src/config/env';
import { environment } from 'src/config/env.config';

// const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // clientId: environment.B2CClientId,
      clientId: environment.azure_client_id,
      authority: `https://login.microsoftonline.com/${environment.azure_tenant}`,
      // authority: environment.tenantPolicyConfig.B2CEndPoint,
      knownAuthorities: [
        `${environment.tenantPolicyConfig.B2CEndPoint}${environment.tenantPolicyConfig.employeeSignInPolicy}`,
        `${environment.tenantPolicyConfig.B2CEndPoint}${environment.tenantPolicyConfig.signInPolicy}`,
        `${environment.tenantPolicyConfig.B2CEndPoint}${environment.tenantPolicyConfig.signUpPolicy}`,
        `${environment.tenantPolicyConfig.B2CEndPoint}${environment.tenantPolicyConfig.forgotPasswordPolicy}`,
      ],
      redirectUri: environment.azure_redirect_uri,
      postLogoutRedirectUri: `${environment.azure_redirect_uri}`
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>([
    ['https://graph.microsoft.com/v1.0/me', [`${environment.azure_client_id}/.default`]],
    [`${environment.awsBaseUrl}/*`, [`${environment.azure_client_id}/.default`]],
    // [`${environment.apiBaseUrl}/*`, [`${environment.azure_client_id}/.default`]],
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      // scopes: [`${environment.B2CClientId}/.default`],
      scopes: [`${environment.azure_client_id}/.default`],
    },
    loginFailedRoute: `${environment.azure_redirect_uri}`
  };
}

export const loggerCallback: ILoggerCallback = (level: number, message: string) => {
  console.error(`${message}`);
};
