export const formatPhoneNumber = (rawInput: string, previousValue?: string) => {
    // Replace non-digits
    let input = rawInput?.replace(/\D/g, '').trim();

    // In cases where the previous value was longer than the new value
    // and the trimmed new value is 3 or 6 characters,
    // remove an extra character if their trimmed values are equal.
    // This ensures, for example, that '123-' can be backspaced to '12' by the user.
    if (previousValue
        && rawInput
        && previousValue.length > rawInput.length
        && (input.length === 3 || input.length === 6)
        && previousValue.replace(/\D/g, '').trim() === input) {
        input = input.slice(0, input.length - 1);
    }

    // Slice first 10 digits, unless first digit is 1 (country code)
    input = input?.length > 10
        ? input.charAt(0) === '1'
            ? input.slice(1, 11)
            : input.slice(0, 10)
        : input?.slice(0, 10);
    if (!input || input.length < 1) {
        return ``;
    } else if (input.length === 3) {
        return `(${input})`;
    } else if (input.length < 4) {
        return input;
    } else if (input.length === 6) {
        return `(${input.slice(0, 3)}) ${input.slice(3, 6)}`;
    } else if (input.length < 7) {
        return `(${input.slice(0, 3)}) ${input.slice(3, input.length)}`;
    } else {
        return `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`;
    }
}